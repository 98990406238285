// react
import React from 'react';
import styles from './video.module.css';
import { IYoutubeVideo } from 'components/seo/schemaOrg';

interface IProps {
  data?: IYoutubeVideo;
}

const YoutubeVideo = ({
  data = { src_id: '', title: '', description: '', duration: '', preview: '' },
}: IProps) => {
  if (!data?.src_id?.length) {
    return null;
  }
  const videoSrcURL = `https://www.youtube.com/embed/${data.src_id}?enablejsapi=1&origin=https%3A%2F%2Fbrocoders.com&amp;theme=light&amp;color=white&amp;modestbranding=1&amp;rel=0&amp;autohide=2`;
  return (
    <div className={styles.container}>
      <iframe
        src={videoSrcURL}
        title={data.title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={styles.video}
      />
    </div>
  );
};

export default YoutubeVideo;
