import React, { FC, useEffect, useState } from 'react';
// icons
import Grade1 from './icons/grade-1.inline.svg';
import Grade2 from './icons/grade-2.inline.svg';
import Grade3 from './icons/grade-3.inline.svg';
import Grade4 from './icons/grade-4.inline.svg';
import Grade5 from './icons/grade-5.inline.svg';
import Grade1Active from './icons/grade-1-active.inline.svg';
import Grade2Active from './icons/grade-2-active.inline.svg';
import Grade3Active from './icons/grade-3-active.inline.svg';
import Grade4Active from './icons/grade-4-active.inline.svg';
import Grade5Active from './icons/grade-5-active.inline.svg';
// styles
import styles from './styles.module.css';

interface IProps {
  averageRating: number;
  ratingCount: number;
  postId: number;
}

const grades = [
  {
    Component: Grade1,
    ActiveComponent: Grade1Active,
    value: 1,
  },
  {
    Component: Grade2,
    ActiveComponent: Grade2Active,
    value: 2,
  },
  {
    Component: Grade3,
    ActiveComponent: Grade3Active,
    value: 3,
  },
  {
    Component: Grade4,
    ActiveComponent: Grade4Active,
    value: 4,
  },
  {
    Component: Grade5,
    ActiveComponent: Grade5Active,
    value: 5,
  },
];

const globalWindow = typeof window !== 'undefined' ? window : undefined;

const Rating: FC<IProps> = ({ averageRating, ratingCount, postId }) => {
  const [rating, setRating] = useState<null | number>(null);

  useEffect(() => {
    if (globalWindow?.localStorage?.getItem(`post_${postId}_rating`)) {
      const savedRating = globalWindow?.localStorage?.getItem(`post_${postId}_rating`);
      savedRating && setRating(Number(savedRating));
    }
  }, [globalWindow?.localStorage?.getItem(`post_${postId}_rating`)]);

  const handleChangeRating = (value: number) => () => {
    setRating(value);
    globalWindow?.localStorage.setItem(`post_${postId}_rating`, value.toString());
  };

  return (
    <div className={styles.container}>
      <div className={styles.ratingContainer}>
        <div className={styles.rating}>{averageRating}</div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Thank you for reading! Leave us your feedback!</div>
          <div className={styles.ratingCount}>{ratingCount} ratings</div>
        </div>
      </div>
      <div className={styles.emotions}>
        {grades.map((item) =>
          item.value === rating ? (
            <button type="button" className={styles.emotion} key={`${item.value}-active`}>
              <item.ActiveComponent />
            </button>
          ) : (
            <button
              type="button"
              className={styles.emotion}
              onClick={handleChangeRating(item.value)}
              key={item.value}
            >
              <item.Component />
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default Rating;
