import React, { useState } from 'react';
import styles from './content-table.module.css';
import clsx from 'clsx';

const ContentTable = ({ data }: { data: string }) => {
  const [isContentOpen, setIsContentOpen] = useState(true);

  const regexpMultiplePossibilities = /#{2,3}.+>/g;
  const regexpSecondOrderContent = /^#{3}/;
  const regexpOpeningTag = /#{2,3} *<\w\d* +id="[\w-\.\?]*">/;
  const regexpForAnchor = /id="[\w-\.]*"/;
  const regexpBeforeClosingTag = /.+(?=<\/\w\d*>)/;

  const arrayWithAllRows = data.match(regexpMultiplePossibilities);
  return (
    <table className={styles.contentsTable}>
      <thead className={styles.tableHeader}>
        <tr>
          <th className={styles.headerRow}>
            <p className={styles.header}>Table of Contents</p>
            <div
              className={
                isContentOpen
                  ? clsx(styles.contentsOpen, styles.displayContentsSwitcher)
                  : styles.displayContentsSwitcher
              }
              onClick={() => setIsContentOpen((prev) => !prev)}
            ></div>
          </th>
        </tr>
      </thead>
      {!!isContentOpen && (
        <tbody className={styles.contentsList}>
          {arrayWithAllRows?.map((row) =>
            row.match(regexpSecondOrderContent) ? (
              <tr>
                <td className={styles.contentSecondItem}>
                  <a
                    className={styles.linkText}
                    href={row.match(regexpForAnchor)?.toString().slice(0, -1).replace('id="', '#')}
                    target="_parent"
                  >
                    {row.replace(regexpOpeningTag, '').match(regexpBeforeClosingTag)?.toString()}
                  </a>
                </td>
              </tr>
            ) : (
              <tr>
                <td className={styles.contentsFirstItem}>
                  <a
                    className={styles.linkText}
                    href={row.match(regexpForAnchor)?.toString().slice(0, -1).replace('id="', '#')}
                    target="_parent"
                  >
                    {row.replace(regexpOpeningTag, '').match(regexpBeforeClosingTag)?.toString()}
                  </a>
                </td>
              </tr>
            )
          )}
        </tbody>
      )}
    </table>
  );
};

export default ContentTable;
