import * as React from 'react';
import clsx from 'clsx';
import { Tag } from '../../Types';
import styles from './tags.module.css';
import { Link } from 'gatsby';
import { Routes } from '../../../routes.js';

interface Props {
  list: ReadonlyArray<Tag>;
  className?: string;
}

function Tags({ list, className }: Props) {
  return (
    <ul className={clsx(styles.list, className)}>
      {list.map((e) => (
        <li key={e.id} className={styles.item}>
          <Link className={styles.link} to={`${Routes.BLOG_TAGS}/${e.slug}/`}>
            {e.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default Tags;
