import React, { FC } from 'react';
// components
import { Link } from 'gatsby';
// css
import clsx from 'clsx';
import styles from './customBanner.module.css';

interface IProps {
  title: string;
  subtitle: string;
  button_text: string;
  button_url: string;
}

const CustomBanner: FC<IProps> = ({ title, subtitle, button_text, button_url }) => (
  <div className={styles.customBanner}>
    <p className={styles.text}>{subtitle}</p>
    <h2 className={clsx(styles.title)}>{title}</h2>
    <Link to={button_url} className={clsx('btn btn_60 btn__white', styles.btn)}>
      {button_text}
    </Link>
  </div>
);

export default CustomBanner;
