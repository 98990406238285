import * as React from 'react';
import BackgroundImage from 'gatsby-background-image';
import clsx from 'clsx';
import Tags from '../tags';
import { ImageFluid, Tag, PostAuthor } from '../../Types';
import styles from './header.module.css';
import Person from 'components/person';
import Breadcrumbs from 'components/breadcrumbs';

interface Props {
  img: ImageFluid;
  date: string;
  title: string;
  topic: { title: string; slug: string };
  tags: ReadonlyArray<Tag>;
  author: PostAuthor;
}

const gradient = `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 37.5%, rgba(0, 0, 0, 0.5) 100%)`;

function Header({ img, date, title, tags, author, topic }: Props) {
  const bgWithGradient = [gradient, img];

  const breadcrumbsList = [
    { title: 'Home', to: '/' },
    { title: 'Blog', to: '/blog/' },
    { title: topic.title, to: `/blog/${topic.slug}` },
  ];

  return (
    <header className={styles.container}>
      <BackgroundImage
        Tag="section"
        fluid={bgWithGradient}
        className={styles.bg}
        preserveStackingContext
      >
        <div
          className={clsx('inner', styles.wrapper)}
          style={{
            paddingBottom: '60px',
          }}
        >
          <Breadcrumbs className={styles.breadcrumbs} data={breadcrumbsList} />
          <div className={styles.content}>
            <div className={styles.date}>{date}</div>
            <h1 className={styles.title}>{title}</h1>
            <Tags list={tags} className={styles.tags} />
            <Person
              title={author.title}
              name={author.name}
              ava={author?.photo?.childImageSharp?.fluid}
            />
          </div>
        </div>
      </BackgroundImage>
    </header>
  );
}

export default Header;
