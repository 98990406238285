import * as React from 'react';
import { PostItem, ImageFluid, PostAuthor } from '../Types';
// styles
import styles from './post.module.css';
//components
import Share from './share';
import Layout from './layout';
import BlogWidget from '../widget';
import Rating from './rating';
import SubscribeBanner from './subscribe-banner';
import CustomBanner from './custom-banner';
import VideoYoutube from './youtube-video';
import FAQ from 'components/faq';
import ContentTable from './content-table';
import GetConsultation from 'components/free-consultation';
import Identificators from 'components/googleAnalyticsIdentificators';
import Embedded from 'components/feedback/booking/embedded';
import ReactMarkdown from 'react-markdown';
import { meta } from '../../../metaData';
// utils
import clsx from 'clsx';
import { convertPostContent, getDate } from '../helpers';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const Routes = require('../../../components/routes').Routes;

interface Props {
  pathContext: {
    post: PostItem;
    background: ImageFluid;
    prePost: ReadonlyArray<any>;
    siteUrl: string;
    authors: PostAuthor[];
  };
}

interface IVideo {
  src_id: string;
  title?: string;
  description?: string;
  duration?: string;
  preview?: string;
}
interface IMetaData {
  title: string;
  description: string;
  url: string;
  image: string;
  datePublished: string;
  authorName: string;
  averageRating: number;
  ratingCount: number;
  youTube?: IVideo;
}

function BlogPost({ pathContext }: Props) {
  const { background, post, prePost, authors } = pathContext;
  const convertedText = convertPostContent(post.content, authors);

  const metaData: IMetaData = {
    title: post.title,
    description: post.short_description,
    url: `${Routes.BLOG}${post.slug}/`,
    image: post.main_photo.childImageSharp.fluid.src,
    datePublished: getDate(post.date),
    authorName: post.author?.name,
    averageRating: post.average_rating,
    ratingCount: post.rating_count,
  };
  if (post.youtube_video && post.youtube_video.src_id) {
    metaData.youTube = {
      src_id: post.youtube_video.src_id,
      title: post.youtube_video?.title,
      description: post.youtube_video?.description,
      duration: post.youtube_video?.duration,
      preview: post.youtube_video?.preview,
    };
  }

  return (
    <Layout
      bg={background}
      title={post.title}
      date={getDate(post.date)}
      tags={post.tags}
      author={post.author}
      topic={post.topic}
      metaData={{
        main: {
          ...meta.blog,
          title: post.page_title ? post.page_title : post.title,
          description: post.page_description ? post.page_description : post.short_description,
          image: { src: post.main_photo.childImageSharp.fluid.src, width: '1200', height: '630' },
          pathname: metaData.url,
        },
        blog: metaData,
        faq: post?.faq?.list,
      }}
    >
      <main className={clsx('main', 'inner')}>
        <div className={styles.content}>
          <div className={styles.meta}>
            <div className={styles.reads}>
              {post.read_time
                .split(' ')
                .map((item, i) => (item == 'read' ? <span key={i}>{item}</span> : `${item} `))}
            </div>
            <Share post={post} />
          </div>
          <ReactMarkdown
            className={styles.text}
            //@ts-ignore
            rehypePlugins={[rehypeRaw, remarkGfm]}
            children={convertedText}
            skipHtml={false}
            components={{
              table: ({ children }) => (
                <div className={styles.table}>
                  <table>{children}</table>
                </div>
              ),
              div({ node, children, id, ...props }) {
                switch (id) {
                  case 'faq-block':
                    return <FAQ data={post?.faq} />;
                  case 'youtube':
                    return <VideoYoutube data={post?.youtube_video} />;
                  case 'contents':
                    return <ContentTable data={post?.content} />;
                  case 'subscribe-banner':
                    return <SubscribeBanner />;
                  case 'calendly':
                    return <Embedded />;
                  case 'consultation-banner':
                    return (
                      <GetConsultation
                        containerClassName={styles.bannerContainer}
                        title={post?.consultation_banner?.title}
                        text={post?.consultation_banner?.subtitle}
                        linkText={post?.consultation_banner?.button_text}
                        linkId={Identificators.GET_A_FREE_CONSULTATION_BANNER}
                      />
                    );
                  default:
                    const custom_banner = post.custom_banners?.find(
                      ({ banner_id }) => banner_id === id
                    );

                    return custom_banner ? (
                      <CustomBanner {...custom_banner} />
                    ) : (
                      <div id={id} {...props}>
                        {children}
                      </div>
                    );
                }
              },
            }}
          />
        </div>
      </main>

      {!!post.average_rating && !!post.rating_count && (
        <section className="section">
          <div className="inner">
            <Rating
              averageRating={post.average_rating}
              ratingCount={post.rating_count}
              postId={Number(post.id)}
            />
          </div>
        </section>
      )}
      <section className="section gray">
        <div className="inner">
          <BlogWidget posts={prePost} />
        </div>
      </section>
    </Layout>
  );
}

export default BlogPost;
