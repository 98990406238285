import * as React from 'react';
import Layout from 'components/layout';
import Header from '../header';
import Footer from 'components/layout/footer';
import { ImageFluid, Tag, PostAuthor } from '../../Types';
import { IProps } from 'components/seo/schemaOrg';

interface Props {
  children: React.ReactNode;
  bg: ImageFluid;
  date: string;
  title: string;
  tags: ReadonlyArray<Tag>;
  author: PostAuthor;
  metaData: IProps;
  topic: object;
}

function BlogPostLayout({ children, bg, date, tags, title, author, metaData, topic }: Props) {
  return (
    <Layout navBtnTextColor="var(--black-color)" metaData={metaData}>
      <Header img={bg} date={date} tags={tags} title={title} author={author} topic={topic} />
      {children}
      <Footer />
    </Layout>
  );
}

export default BlogPostLayout;
