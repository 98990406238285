import React, { FC } from 'react';
import loadable from '@loadable/component';
// import SubscribeForm from 'components/subscribe-form';
// css
import styles from './banner.module.css';

const SubscribeForm = loadable(() => import('components/subscribe-form'));

const SubscribeBanner: FC = () => {
  return (
    <div className={styles.banner}>
      <h1 className={styles.title}>Subscribe to our newsletters</h1>
      <div className={styles.form}>
        <SubscribeForm variant="banner" />
      </div>
    </div>
  );
};

export default SubscribeBanner;
