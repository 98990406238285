import * as React from 'react';
import styles from './share.module.css';
import { TwitterShareButton, LinkedinShareButton, FacebookShareButton } from 'react-share';
import useSiteMetadata from 'hooks/useSiteMetaData';

const Routes = require('../../../routes').Routes;

import { PostItem } from '../../Types';

function Share({ post }: { post: PostItem }) {
  const { siteUrl } = useSiteMetadata();

  const shareUrl = `${siteUrl}${Routes.BLOG}${post.slug}/`;

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        <FacebookShareButton url={shareUrl} quote={post.title}>
          <span className={styles.fb} />
        </FacebookShareButton>
        <LinkedinShareButton url={shareUrl}>
          <span className={styles.li} />
        </LinkedinShareButton>
        <TwitterShareButton title={post.title} url={shareUrl}>
          <span className={styles.tw} />
        </TwitterShareButton>
      </div>
    </div>
  );
}

export default Share;
